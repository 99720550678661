@import (css) url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);

body {
    background: #000;
    color: #fff;
    font-family: Roboto, Arial, sans-serif;
}

main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .name {
            display: flex;
        }
    }

    .linkedin {
        display: flex;
        align-items: flex-end;
    }

    hr {
        width: 80%;
    }

    .hiring {
        width: 80%;
        margin: auto;
    }

    h1 {
        font-size: 5em;
        font-weight: 300;
        align-self: center;
    }

    h2 {
        font-size: 1.2em;

        a {
            text-decoration: none;
            color: #fff;
        }
    }
}

.margin0 {
    margin: 0;
}

@media (max-width: 573px) {
    main {
        font-size: 13px;

        .linkedin {
            img {
                height: 5em;
            }
        }
    }
}

#info {
    width: auto;
    height: auto;
    line-height: 1.5em;
    display: none;
    position: relative;
}

.overlay {
    position: absolute;
    background: black;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;

    .summary {
        text-align: center;
        padding: 1em;
        p {
            margin: 0;

            &:last-child {
                font-size: 0.5em;
            }
        }
    }
}

@media screen and (prefers-color-scheme: light) {
    .overlay {
        background-color: white;
        color: black;
    }
}